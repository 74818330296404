<template>
  <div>
    <div class="p-5 max-w-md bg-white rounded-lg shadow-lg">
      <p class="mb-5 text-left">
        Haga clic en el siguiente botón para sincronizar las quejas con la
        Superintendencia Financiera de Colombia
      </p>
      <Button @click.native="sync" :loading="loading" :disable="loading">
        Sincronizar
      </Button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async sync() {
      this.loading = true
      const response = await this.$api.axios.get('/api/sfc')
      this.loading = false
      if (response.status !== 200) {
        this.$showNotification('error', 'Hubo un error al sincronizar')
        return
      }
      this.$showNotification('success', 'Sincronización completa')
    },
  },
}
</script>

<style></style>
